import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const testimonials = [
  {
    text: 'Ali\'s full-stack expertise was crucial in revamping our e-commerce platform. His work on both the frontend and backend significantly improved our site\'s performance and user experience.',
    name: 'Rachel Chen',
    position: 'CTO, ShopEase',
  },
  {
    text: 'We hired Ali to develop a custom CRM system. His ability to understand our complex business logic and translate it into a user-friendly application was impressive. The system has streamlined our operations considerably.',
    name: 'David Oyelowo',
    position: 'Operations Manager, GlobalTech Solutions',
  },
  {
    text: 'Ali\'s proficiency in modern web technologies like React and Node.js allowed us to launch our startup\'s MVP ahead of schedule. His code was clean, well-documented, and easy to maintain.',
    name: 'Sophia Nguyen',
    position: 'Co-founder, InnovateLab',
  },
  {
    text: 'Working with Ali on our data visualization project was a great experience. He efficiently handled both the backend data processing and the frontend charting, delivering a powerful analytics dashboard.',
    name: 'Marcus Blum',
    position: 'Lead Data Scientist, DataViz Pro',
  },
  {
    text: 'Ali\'s expertise in API development and integration was invaluable for our IoT project. He created a robust backend that seamlessly handles data from thousands of devices in real-time.',
    name: 'Lena Kowalski',
    position: 'IoT Project Lead, SmartHome Systems',
  },
  {
    text: 'Ali developed a sophisticated machine learning pipeline for our recommendation engine. His full-stack skills ensured smooth integration with our existing systems and a user-friendly interface for our team.',
    name: 'Jamal Hassan',
    position: 'AI Research Director, TechNova Inc.',
  },
  {
    text: 'We contracted Ali to optimize our web app\'s performance. His deep understanding of both frontend and backend optimizations resulted in a 40% increase in our app\'s speed and responsiveness.',
    name: 'Yuki Tanaka',
    position: 'Product Manager, FastApp Solutions',
  },
  {
    text: 'Ali\'s work on our blockchain-based supply chain tracking system was exceptional. He navigated the complexities of blockchain technology while delivering a intuitive interface for our non-technical users.',
    name: 'Elena Rodriguez',
    position: 'Blockchain Lead, SupplyChain Innovations',
  },
  {
    text: 'For our EdTech platform, Ali implemented a real-time collaboration feature that greatly enhanced our user engagement. His expertise in WebSockets and state management was crucial to the project\'s success.',
    name: 'Michael O\'Brien',
    position: 'CTO, EduConnect',
  },
  {
    text: 'Ali\'s full-stack skills were instrumental in developing our cloud-based project management tool. His work on containerization and CI/CD pipelines significantly improved our development workflow.',
    name: 'Aisha Patel',
    position: 'DevOps Manager, CloudWorks',
  },
];
const StyledTestimonialsSection = styled.section`
  margin: 0 auto;
  padding: 120px 20px;
  overflow: hidden;
  background-color: var(--navy);
  max-width: unset;
  @media (max-width: 768px) {
    padding: 0px;
    overflow: unset;
    height: 450px;
    position: relative;
    padding-top: 100px;
  }

  h2 {
    font-size: clamp(28px, 5vw, 36px);
    text-align: center;
    margin-bottom: 60px;
    color: var(--lightest-slate);
    &:before {
      position: relative;
      bottom: 4px;
      counter-increment: section;
      content: '0' counter(3) '.';
      margin-right: 10px;
      color: var(--green);
      font-family: var(--font-mono);
      font-size: clamp(var(--fz-md), 3vw, var(--fz-xl));
      font-weight: 400;
    }
  }
`;

const StyledTestimonial = styled.div`
  background-color: var(--light-navy);
  border-radius: 1rem;
  padding: 30px;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 10px 30px -15px var(--navy-shadow);
  transition: 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  background: rgb(30 57 100 / 50%);
  backdrop-filter: blur(10px);
  color: rgb(238, 238, 238);
  border: 0px solid;

  &:hover {
    transform: translateY(-5px);
  }

  p {
    font-style: italic;
    color: var(--slate);
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .client-info {
    .client-details {
      h4 {
        color: var(--lightest-slate);
        font-size: 18px;
        margin-bottom: 8px;
      }

      span {
        color: var(--green);
        font-size: 16px;
      }
    }
  }
`;

const StyledSlider = styled(Slider)`
  .slick-list {
    margin: 0 -15px;
  }
  .slick-prev:before {
    content: '←';
    display: none;
  }
  .slick-next:before {
    display: none;
  }
  .slick-slide > div {
    margin: 0 15px;
  }

  .slick-track {
    display: flex !important;
    justify-content: center;
  }

  .slick-slide {
    height: inherit !important;
    > div {
      height: 100%;
    }
  }
  .slick-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    /* height: 286px; */
    @media (max-width: 768px) {
      position: absolute;
      height: 100%;
      width: 100%;
      margin: auto;
      overflow: unset !important;
    }
  }

  @media (max-width: 768px) {
    .slick-arrow {
      display: none !important;
    }
  }
`;

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3.5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  cssEase: 'ease',
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const ClientTestimonials = () => (
  <StyledTestimonialsSection id="testimonials">
    <h2 className="numbered-heading" style={{ maxWidth: '1200px', margin: '0 auto 40px' }}>
      Testimonials
    </h2>
    <StyledSlider {...sliderSettings}>
      {testimonials.map((testimonial, index) => (
        <StyledTestimonial key={index}>
          <p>{testimonial.text}</p>
          <div className="client-info">
            <div className="client-details">
              <h4>{testimonial.name}</h4>
              <span>{testimonial.position}</span>
            </div>
          </div>
        </StyledTestimonial>
      ))}
    </StyledSlider>
  </StyledTestimonialsSection>
);

export default ClientTestimonials;
